
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { FortmaticConnector } from '@web3-react/fortmatic-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { BscConnector } from '@binance-chain/bsc-connector'
import { LedgerConnector } from '@web3-react/ledger-connector';



export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: "https://rpc.ankr.com/eth",
  },
  chainId: 1
})

export const bscConnector = new BscConnector({ supportedChainIds: [1
  ,97, 43114
  ] })

  export const injected = new InjectedConnector({ supportedChainIds: [1
    ,97, 43114
  ] })
  export const ledger = new LedgerConnector({ supportedChainIds: [1
    ,97, 43114
  ] })

export const fortmatic = new FortmaticConnector({ apiKey: 'pk_test_D58C7F46E173BEDB', chainId: 1, pollingInterval: 15000 })

//Test Rinkeby, Kovan, Ropsten : pk_test_D58C7F46E173BEDB
//Production localhost : pk_live_724ABCF7B9489C5C